body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background-color: #f2efed;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%; /* Ensures body and html elements take up 100% height */
}

body > * {
  height: 100%;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
